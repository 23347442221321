<template>
    <b-form-group
                label="Empresa"
                label-for="select-empresa"
                label-class="font_size_label"
            >
              <v-select
                  id="select-empresa"
                  v-model="value.empresa"
                  variant="custom"
                  item-text="descricao"
                  item-value="id_empresa"
                  :label="value.empresa ? 'nome_empresa' : empresaFilter"
                  placeholder="Selecione uma empresa"
                  :disabled="disableAll"
                  :values="empresaSelect"
                  :options="empresaSelect"
                  :loading="loading"
                  @input="value.endereco = null"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
                <template #option="item">
                  <strong v-if="item.documento.length == 14">{{item.documento | VMask('##.###.###/####-##')}}</strong>
                  <strong v-else>{{item.documento | VMask('###.###.###-##')}}</strong><br>
                  <span v-if="empresaFilter != 'documento_formatado' && item[empresaFilter]">{{ item[empresaFilter] }}</span>
                  <span v-else>{{ item.nome_empresa }}</span>
                </template>
              </v-select>
              <b-form-radio-group
                id="radio-group-empresa"
                v-model="empresaFilter"
                name="radio-sub-component"
                class="mt-10px radio-empresa"
                size="sm"
                @input="value.empresa = null"
              >
                <span class="mr-1">Filtrar por:</span>
                <b-form-radio value="documento_formatado">CNPJ/CPF</b-form-radio>
                <b-form-radio value="razao_social">Razão Social</b-form-radio>
                <b-form-radio value="nome_empresa">Nome Fantasia</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
  </template>
  
  <script>
  import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio
} from 'bootstrap-vue';
import vSelect from 'vue-select';

  export default {
    components: {
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BFormRadio,
        vSelect
    },

    props: {
      empresaSelect: {
        type: Array,
        required: true,
        default: () => []
      },
      value: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        default: true
      },
      disableAll: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        empresaFilter: 'documento_formatado'
      }
    },
  }
  </script>
